import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild
 } from '@angular/animations';
 export const slideInAnimation =
    trigger('routeAnimations', [
        //HOME
        transition('home => *', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         
         //CONTACT
         transition('contact => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //REGISTER
         transition('register => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //CART
         transition('cart => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //CHECKOUT
         transition('checkout => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //CUSTOMER
         transition('customer => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //CATEGORIES
         transition('categories => category', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         transition('categories => home', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
        ]),

         //CATEGORY
         transition('category => product', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         transition('category => category', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         transition('category => home', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
        ]),
        transition('category => categories', [
         style({transform: 'translateX(-100%)'}),    
         animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
     ]),

        //PRODUCT
         transition('product => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),

         //BLOG
         transition('blog => post', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         transition('blog => blog', [
            style({transform: 'translateX(100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         transition('blog => home', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
        ]),

        //POST
         transition('post => *', [
            style({transform: 'translateX(-100%)'}),    
            animate('0.6s ease-in-out', style({transform: 'translateX(0%)'}))
         ]),
         
 ]);