import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    subtitle: string;
    icon: string;
    class: string;
}

const uri = AppSettings._URI_BACK;

export const ROUTES: RouteInfo[] = [
    //{ path: uri + 'dashboard', title: 'Dashboard', subtitle:'Home',  icon: 'dashboard', class: 'info' },
    { path: uri + 'afiliados', title: 'Afiliados',  subtitle:'Configurar', icon:'supervised_user_circle', class: 'warning' },
    { path: uri + 'partners', title: 'Partners',  subtitle:'Configurar', icon:'shopping_basket', class: 'success' },
];

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
 
  constructor() { 
    
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
