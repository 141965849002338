import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './gestio/components/components.module';

import { AppComponent } from './app.component';

//PAGINATOR
import { NgxPaginationModule } from 'ngx-pagination'; 

//DATA SERVICE 
import { 
  MatTableModule, MatPaginatorModule, MatSortModule,
  MatProgressSpinnerModule, MatCheckboxModule, MatTabsModule,
} from '@angular/material';
import {MatChipsModule} from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { DataService } from './data.service';
//FIREBASE
import { AngularFireModule } from 'angularfire2';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
//img upload
import { FileUploadModule,FileSelectDirective } from 'ng2-file-upload';
import { AgmCoreModule } from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FrontLayoutComponent } from './layouts/front-layout/front-layout.component';
//PLUGINS PANEL
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDropzoneModule } from 'ngx-dropzone';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FileUploadModule,
    MatIconModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    NgxPaginationModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    FrontLayoutComponent,
  ],
  exports: [

  ],
  providers: [DataService,AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
