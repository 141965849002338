import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from 'angularfire2/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
//import { map } from 'rxjs/operators';
import {Md5} from 'ts-md5/dist/md5';
import { User } from './users';
//import { UserInfo } from 'os';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    subscription: Subscription;

    constructor(private db: AngularFirestore) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        //console.log(this.currentUserSubject);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    login(username: string, password: string, callback) {
        const md5 = new Md5();
        var user: User;
        var passMd5 = md5.appendStr(password).end().toString();
        //this.db.collection<User>('/usuaris', ref => ref.orderByChild('username').equalTo(username)).valueChanges().subscribe(users => {
        this.db.collection<User>('/users', ref => ref.where('username', '==', username)).valueChanges().subscribe(users => {   
            if (users.length) {
                user = users[0];
                if(user.password == passMd5){
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    callback(true);
                }else{
                    callback(false);
                }
            } else {
                callback(false);
            }
        });

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        window.location.href = '/gestio/login'; 
    }
}