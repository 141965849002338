import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FrontLayoutComponent } from './layouts/front-layout/front-layout.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'csoon',
    pathMatch: 'full',
  }, 
  {
    path: '',
    component: FrontLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/front-layout/front-layout.module#FrontLayoutModule'
    }]
  },
  {
    path: 'gestio',
    redirectTo: 'gestio/login',
    pathMatch: 'full',
  }, 
  {
    path: 'gestio',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes,{
       useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
